import { Component, OnInit, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";

import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/services/shared.service";
import { ClustersService } from "../../clusters/clusters.service";
import { ChannelsService } from "../channels.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { SourcesService } from "../../sources/sources.service";
import { TranscodingProfilesService } from "../../transcoding-profiles/transcoding-profiles.service";

import { Source, MediaConnectFlow } from "src/app/models/shared";
import { AdaptiveChannel, DeliveryChannel } from "../channel";
import { TranscodingProfile } from "../../transcoding-profiles/transcoding-profile";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleCasePipe } from "@angular/common";
import { TitleService } from "../../../services/title.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";
interface BitrateOption {
    profile?: TranscodingProfile;
    profile_id: number;
    source?: Source;
    source_id: number;
    name: string;
    kbps: number;
    gpu_id?: number;
}

@Component({
    selector: "app-channel-transcode-form",
    templateUrl: "./channel-form.component.html",
    providers: [TitleCasePipe]
})
export class ChannelFormTranscodeComponent implements OnInit, OnDestroy {
    channel: AdaptiveChannel;
    channels: (AdaptiveChannel | DeliveryChannel | MediaConnectFlow)[];
    channelId: number;
    channelName: string;
    channelNames: string[];
    channelCluster: string;

    sources: Source[];
    objectType: string = null;
    objectCluster: string = null;
    objectName: string = null;

    existingChannel: AdaptiveChannel;

    action: string;
    loading = true;
    saving = false;
    showAdvanced = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    startDisabled = false;
    constants = Constants;
    multiGpu = false;
    slateSourceIncluded = false;

    page = 1;
    pageSize = 10;

    selectedBitrates: BitrateOption[] = [];
    profiles: TranscodingProfile[] = [];
    profilesLoading = true;

    targetBXsLoading = false;
    targetBXs = [
        { name: this.translate.instant("PREFER_PRIMARY_BROADCASTERS"), id: -1, cluster: null },
        { name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"), id: -2 },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: -3 },
        { name: this.translate.instant("PREFER_BACKUP_BROADCSTER"), id: -4, cluster: null }
    ];
    targetBXsBase = this.targetBXs;

    transcodeEncoderOptions = [
        { name: "Auto Detect", id: "any" },
        { name: "Nvidia", id: "nvidia_hw" },
        { name: "Intel", id: "intel_hw" },
        { name: "x264", id: "x264" }
    ];

    transcodingSourceId = null;
    slateSourceId: number | null;
    transcodedSourceBitrate: number;
    cropMode: string;
    crop: boolean;
    pixelCropHeight: number;
    pixelCropLeft: number;
    pixelCropTop: number;
    pixelCropWidth: number;
    percentageCropHeight: number;
    percentageCropLeft: number;
    percentageCropTop: number;
    percentageCropWidth: number;

    clusterDNSPrefix: string;
    transcodingSource: Source;

    private channelsSubscription: Subscription;
    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private clusterService: ClustersService,
        private sharedService: SharedService,
        private broadcastersService: BroadcastersService,
        private sourcesService: SourcesService,
        private transcodingProfilesService: TranscodingProfilesService,
        private modalService: ModalService,
        private cs: ChannelsService,
        private mixpanelService: MixpanelService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe,
        private location: Location
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(async params => {
            this.channelId = urlBuilder.decode(params.get("channelId"));
            this.channelName = params.get("name");
            this.channelCluster = params.get("cluster");
            this.action = params.get("action");

            if (params.get("objecttype")) this.objectType = params.get("objecttype");
            if (params.get("objectcluster")) this.objectCluster = params.get("objectcluster");
            if (params.get("objectname")) this.objectName = params.get("objectname");

            if (this.channelName && this.channelId) {
                this.channel = Object.assign({}, this.cs.getCachedAdaptiveChannel(this.channelId));
                this.existingChannel = _.cloneDeep(this.channel);

                // Check if channel found in cache, if not get channels and channel
                if (this.sharedService.isEmptyObject(this.channel) || !this.channel.hasFullDetails) {
                    await this.cs.getAdaptiveChannels().toPromise();
                    this.channel = Object.assign({}, this.cs.getCachedAdaptiveChannel(this.channelId));

                    const channel = await this.cs.getAdaptiveChannel(this.channel.id);
                    if (channel) this.channel = Object.assign({}, channel);
                    this.existingChannel = _.cloneDeep(this.channel);

                    this.loading = false;
                } else this.loading = false;
            } else this.loading = false;

            this.prepForm();
        });
    }

    prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.channel.resourceTags);
            if (this.action === "edit") {
                this.nameControl.setValue(this.channel.name);
                this.isEdit = true;
            } else if (this.action === "clone") {
                this.isClone = true;
                this.channel.name = "";
                this.channel.muted = this.channel.active_mute ? 1 : 0;
            }

            if (this.channel) {
                this.channel = this.channel as AdaptiveChannel;

                if (this.channel.broadcaster_id == null) this.channel.broadcaster_id = -1;

                if (this.channel.bitrates && this.channel.bitrates.length > 0) {
                    this.transcodingSourceId = _.first(this.channel.bitrates).source_id;
                    this.selectedBitrates = this.channel.bitrates;
                    this.multiGpu = this.channel.bitrates[0].gpu_id != null;
                    this.selectedBitrates.forEach(b => {
                        if (b.gpu_id == null) b.gpu_id = 0;
                    });
                    this.profiles = _.filter(this.profiles, p => {
                        return !_.find(this.selectedBitrates, b => {
                            return b.profile_id === p.id;
                        });
                    });
                    this.slateSourceId = this.channel.slate_source_id || null;
                    this.slateSourceIncluded = !!this.channel.slate_source_id;
                }
                if (this.channel.transcoded_source_bitrate)
                    this.transcodedSourceBitrate = this.channel.transcoded_source_bitrate / 1000;
                if (this.channel.transcode_crop_mode === 0) {
                    this.crop = false;
                    this.cropMode = "pixels";
                } else {
                    this.crop = true;
                    if (this.channel.transcode_crop_mode === 1) {
                        this.cropMode = "pixels";
                        this.pixelCropHeight = this.channel.transcode_crop_height;
                        this.pixelCropLeft = this.channel.transcode_crop_left;
                        this.pixelCropTop = this.channel.transcode_crop_top;
                        this.pixelCropWidth = this.channel.transcode_crop_width;
                    } else {
                        this.cropMode = "percentages";
                        this.percentageCropHeight = this.channel.transcode_crop_height;
                        this.percentageCropLeft = this.channel.transcode_crop_left;
                        this.percentageCropTop = this.channel.transcode_crop_top;
                        this.percentageCropWidth = this.channel.transcode_crop_width;
                    }
                }

                if (this.channel.broadcaster_cluster_id !== null) {
                    this.getClusterChannelNames(this.channel.broadcaster_cluster_id);
                    this.getTargetBroadcasters(this.channel.broadcaster_cluster_id);
                    this.getClusterDNSPrefix(this.channel.broadcaster_cluster_id);
                }
            }
        }

        if (!this.channel && !this.isClone && !this.isEdit) {
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("CHANNEL") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.channel && this.channel.name ? this.channel.name : "")
        );
    }

    resetForm() {
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);

        // Channel
        this.channel = new AdaptiveChannel();
        // UI
        this.selectedBitrates = [];
        this.cropMode = "pixels";
        this.crop = false;
        this.pixelCropHeight = null;
        this.pixelCropLeft = null;
        this.pixelCropTop = null;
        this.pixelCropWidth = null;
        this.percentageCropHeight = null;
        this.percentageCropLeft = null;
        this.percentageCropTop = null;
        this.percentageCropWidth = null;
        this.transcodingSource = null;

        this.channel.segment_on_scte35 = 1;
        this.channel.skip_cue_in = 0;
        this.channel.drop_first_segment = 1;
        this.channel.order_ascending = 1;
        this.channel.timecode_mode = "system";
        this.channel.disable_cmaf = true;
        this.channel.disable_synchronization = false;
        this.channel.strict_chunks = false;
        this.channel.copy_closed_captions = true;
        this.channel.billing_code = "";
        this.channel.billing_password = "";
        this.channel.transcode_encoder = "any";
        this.channel.transcode_threads = null;
        this.channel.support_multiaudio = false;
        this.channel.alt_channel_id = null;
    }

    ngOnInit() {
        // Channels
        this.cs.getAdaptiveChannels();
        this.cs.getDeliveryChannels();
        this.channelsSubscription = this.cs.channels.subscribe(
            (channels: (AdaptiveChannel | DeliveryChannel | MediaConnectFlow)[]) => {
                this.channels = channels;
                this.getClusterChannelNames(this.channel.broadcaster_cluster_id);
            }
        );

        // Sources
        this.profilesLoading = true;
        this.sourcesService.refreshSources(true);
        this.sourcesService.sources.pipe(take(1)).subscribe((sources: Source[]) => {
            if (this.objectType && this.objectCluster && this.objectName) {
                const source = _.find(sources, s => {
                    return s.name === this.objectName && s.inputCluster.dns_prefix === this.objectCluster;
                });

                if (source) {
                    this.transcodingSourceId = source.id;

                    if (source.broadcaster_cluster_id) {
                        this.channel.broadcaster_cluster_id = source.broadcaster_cluster_id;
                        this.getClusterChannelNames(this.channel.broadcaster_cluster_id);
                        this.getTargetBroadcasters(this.channel.broadcaster_cluster_id);
                        this.getClusterDNSPrefix(this.channel.broadcaster_cluster_id);
                    }

                    if (source.target_broadcaster_id) this.channel.broadcaster_id = source.target_broadcaster_id;
                }
            }
            this.sources = sources;
            this.getTranscodingSource(this.transcodingSourceId);
        });

        // Transcoding Profiles
        this.transcodingProfilesService.refreshTranscodingProfiles();
        this.transcodingProfilesService.transcodingProfiles
            .pipe(take(1))
            .subscribe((profiles: TranscodingProfile[]) => {
                this.profiles = profiles;
                this.profiles = _.filter(profiles, p => {
                    return !_.find(this.selectedBitrates, b => {
                        return b.profile_id === p.id;
                    });
                });
                this.profilesLoading = false;
            });
    }

    ngOnDestroy() {
        if (this.channelsSubscription) this.channelsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        if (
            this.selectedBitrates.length === 0 ||
            (this.transcodingSource && this.transcodingSource.disable_autopull) ||
            (this.channel.disable_cmaf && this.channel.disable_hls)
        ) {
            this.saving = false;
            return;
        }

        this.getClusterDNSPrefix(this.channel.broadcaster_cluster_id);

        if (this.crop === false) {
            this.channel.transcode_crop_mode = 0;
        } else if (this.crop === true) {
            if (this.cropMode === "pixels") {
                this.channel.transcode_crop_mode = 1;
                this.channel.transcode_crop_height = this.pixelCropHeight;
                this.channel.transcode_crop_left = this.pixelCropLeft;
                this.channel.transcode_crop_top = this.pixelCropTop;
                this.channel.transcode_crop_width = this.pixelCropWidth;
            } else if (this.cropMode === "percentages") {
                this.channel.transcode_crop_mode = 2;
                this.channel.transcode_crop_height = this.percentageCropHeight;
                this.channel.transcode_crop_left = this.percentageCropLeft;
                this.channel.transcode_crop_top = this.percentageCropTop;
                this.channel.transcode_crop_width = this.percentageCropWidth;
            }
        }

        if (!this.multiGpu) this.selectedBitrates.forEach(b => (b.gpu_id = null));

        const model = {
            name: this.nameControl.value,
            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.channel.alertingProfile.id,
            broadcaster_cluster_id: this.channel.broadcaster_cluster_id,
            type: "adaptive",
            broadcaster_id: this.channel.broadcaster_id,
            webvtt_cc: this.channel.webvtt_cc,
            hls_chunks: this.channel.hls_chunks,
            hls_chunk_duration: this.channel.hls_chunk_duration,
            is_transcoding: 1,
            log_scte: this.channel.log_scte,
            is_source_included: this.channel.is_source_included ? this.channel.is_source_included : 0,

            segment_on_scte35: this.channel.segment_on_scte35,
            skip_cue_in: this.channel.skip_cue_in,
            drop_first_segment: this.channel.drop_first_segment,
            order_ascending: this.channel.order_ascending,

            copy_closed_captions: this.channel.copy_closed_captions,
            timecode_mode: this.channel.timecode_mode,
            transcoded_source_bitrate: this.transcodedSourceBitrate ? this.transcodedSourceBitrate * 1000 : 0,
            bitrates: this.selectedBitrates.map(bitrate =>
                Object.assign(
                    { source_id: this.transcodingSourceId },
                    _.pick(bitrate, ["profile_id", "name", "kbps", "gpu_id"])
                )
            ),
            transcode_crop_left: this.channel.transcode_crop_left ? this.channel.transcode_crop_left : 0,
            transcode_crop_top: this.channel.transcode_crop_top ? this.channel.transcode_crop_top : 0,
            transcode_crop_width: this.channel.transcode_crop_width ? this.channel.transcode_crop_width : 0,
            transcode_crop_height: this.channel.transcode_crop_height ? this.channel.transcode_crop_height : 0,
            transcode_crop_mode: this.channel.transcode_crop_mode ? this.channel.transcode_crop_mode : 0,
            disable_hls: this.channel.disable_hls,
            disable_cmaf: this.channel.disable_cmaf,
            disable_synchronization: this.channel.disable_synchronization,
            strict_chunks: this.channel.strict_chunks,
            ignore_transcode_thresholds: this.channel.ignore_transcode_thresholds,
            muted: this.channel.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.channel.is_enabled,
            billing_code: this.channel.billing_code,
            billing_password: this.channel.billing_password,
            slate_source_id: this.slateSourceIncluded ? this.slateSourceId : null,
            force_i_frame_on_scte: this.channel.force_i_frame_on_scte,
            transcode_encoder: this.channel.transcode_encoder,
            transcode_threads: this.channel.transcode_threads,
            html_overlay_url: null,
            support_multiaudio: this.channel.support_multiaudio,
            alt_channel_id: this.channel.alt_channel_id
        };

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingChannel, []);
            const newBitrates = this.compareBitrates(this.existingChannel, model);
            if (!newBitrates) {
                delete changedData["bitrates"];
            } else {
                Object.assign(changedData, { bitrates: newBitrates });
            }
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedChannel = await this.cs.updateChannel(this.channel, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedChannel;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "CHANNEL",
                        async () => {
                            const updateAndRestartChannel = await this.cs.updateChannel(this.channel, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartChannel) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart transcoded channel", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularChannelUrl(
                                        this.channel.id,
                                        Constants.urls.channelTypes.transcoded,
                                        model.name
                                    )
                                );
                            } else this.saving = false;
                        },
                        model.name
                    );
                    this.saving = false;
                } else if (updatedChannel) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update transcoded channel", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularChannelUrl(
                            this.channel.id,
                            Constants.urls.channelTypes.transcoded,
                            model.name
                        )
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(this.channel.id, Constants.urls.channelTypes.transcoded, model.name)
                );
            }
        } else {
            const result = await this.cs.addChannel(model, model.type);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create transcoded channel");
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(result.id, Constants.urls.channelTypes.transcoded, model.name)
                );
            } else this.saving = false;
        }
    }

    compareBitrates(existingChannel, model) {
        const reducedOriginal = _.map(existingChannel.bitrates, bitrate => {
            return _.pick(bitrate, "profile_id", "source_id", "name", "kbps", "gpu_id");
        });
        if (_.isEqual(reducedOriginal, model.bitrates)) {
            return false;
        } else {
            return model.bitrates;
        }
    }

    cancel() {
        if (!this.isEdit && !this.isClone) return this.router.navigate([Constants.urls.channels]);
        this.router.navigate(
            urlBuilder.getRegularChannelUrl(
                this.channel.id,
                Constants.urls.channelTypes.transcoded,
                this.existingChannel.name
            )
        );
    }

    back() {
        this.location.back();
    }

    async reloadBroadcasterTargets(broadcasterClusterId) {
        this.channel.broadcaster_id = null;
        this.getClusterChannelNames(broadcasterClusterId);
        await this.getTargetBroadcasters(broadcasterClusterId);
        this.getClusterDNSPrefix(broadcasterClusterId);
        this.channel.broadcaster_id = -1;
    }

    getClusterChannelNames(id: number) {
        const filteredChannels = _.filter(this.channels, channel => {
            if (this.existingChannel && this.existingChannel.mediaconnect) return channel.mediaconnect;
            else {
                if (channel.adaptive || channel.delivery) return id === channel.broadcaster_cluster_id;
                return false;
            }
        });
        let filteredChannelNames = _.map(filteredChannels, "name");

        if (this.isEdit) filteredChannelNames = _.without(filteredChannelNames, this.channelName);

        this.channelNames = filteredChannelNames;
    }

    getClusterDNSPrefix(id: number) {
        const cluster = this.clusterService.getCachedCluster(null, id);
        if (cluster) this.clusterDNSPrefix = cluster.dns_prefix;
    }

    async getTargetBroadcasters(id: number) {
        this.targetBXsLoading = true;
        this.targetBXs = this.targetBXsBase;
        if (!id) {
            this.targetBXsLoading = false;
            return;
        }

        try {
            const broadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
            if (broadcasters && broadcasters.length > 0) {
                this.targetBXs = this.targetBXsBase.concat(
                    _.map(broadcasters, broadcaster => {
                        return {
                            id: broadcaster.id,
                            name: broadcaster.name,
                            type: "broadcaster",
                            generalStatus: broadcaster.generalStatus
                        };
                    })
                );
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log("error", e);
        }

        this.targetBXsLoading = false;
    }

    getSourceBroadcasterClusterID(id: number) {
        if (!this.sources) return null;
        if (this.sources.length === 0) return null;
        if (this.sources.find(s => s.id === id)) return this.sources.find(s => s.id === id).broadcaster_cluster_id;
        else return null;
    }

    getTranscodingSource(id: number) {
        if (!this.sources) return null;
        if (this.sources.length === 0) this.transcodingSource = null;
        if (this.sources.find(s => s.id === id)) this.transcodingSource = this.sources.find(s => s.id === id);
        else this.transcodingSource = null;
    }

    profileKbps(profile: TranscodingProfile) {
        if (profile.keep_video || profile.keep_audio) return null;
        const audioBitrate = profile.do_audio ? profile.audio_bitrate + 8 : 0;
        const videoBitrate = profile.do_video ? profile.bitrate_avg + 25 : 0;
        return Math.floor((videoBitrate + audioBitrate) * 0.1021) * 10 || null;
    }

    selectTranscodedBitrate(profile: TranscodingProfile) {
        this.selectedBitrates.push({
            profile,
            profile_id: profile.id,
            source: null,
            source_id: null,
            name: profile.name,
            kbps: this.profileKbps(profile),
            gpu_id: 0
        });
        this.profiles = this.profiles.filter(p => {
            return p.id !== profile.id;
        });
    }

    deselectTranscodedBitrate(bitrate: BitrateOption) {
        this.profiles.push(bitrate.profile);
        this.profiles = this.sharedService.sort(this.profiles || [], "profile", "asc");
        this.selectedBitrates = this.selectedBitrates.filter(b => {
            return b.profile_id !== bitrate.profile_id;
        });
    }

    transcodingSourceChanged(id: number) {
        this.getTranscodingSource(id);

        if (!this.isEdit && !this.isClone && this.transcodingSource && this.transcodingSource.resourceTags) {
            this.channel.resourceTags = _.unionBy(this.channel.resourceTags, this.transcodingSource.resourceTags, "id");
        }
    }

    mutuallyExclusiveSources(isSlateToggle) {
        if (isSlateToggle) {
            this.channel.is_source_included = 0;
        } else {
            this.slateSourceIncluded = false;
        }

        this.transcodedSourceBitrate = null;
        this.slateSourceId = null;
    }

    uniqueSourceFilter() {
        return source => source.id !== this.transcodingSourceId && source.id !== this.slateSourceId;
    }

    encoderChanged() {
        if (this.channel.transcode_encoder !== "any") this.multiGpu = false;
    }

    alternativeChannelFilter(channel: any) {
        return !!channel.is_transcoding;
    }
}
